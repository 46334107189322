<template>
<div class="main">
    <router-link class="nav" :to="{name:'Home'}">首页</router-link>

    <div class="login-wrp">
        <h2>登录</h2>
        <el-row>
            <el-col :span="6">
                <label>手机号</label>
            </el-col>
            <el-col :span="18">
                <el-input type="text" placeholder="请输入手机号" v-model="phone"></el-input>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <label>密码</label>
            </el-col>
            <el-col :span="18">
                <el-input type="password" placeholder="请输入密码" v-model="psword" show-password></el-input>
            </el-col>
        </el-row>
        <el-row class="radio-wrp">
            <el-radio v-model="radio" label="1">用户</el-radio>
            <el-radio v-model="radio" label="2">商家</el-radio>
        </el-row>
        <el-row class="login-btns">
            <el-button type="primary" size="medium" @click="login">登录</el-button>
        </el-row>
    </div>
</div>
</template>

<script>
let app = null

export default {
    data() {
        return {
            phone: "",
            psword: "",
            radio: "1"
        }
    },
    created() {
        app = this.$.appContext.app
    },
    methods: {
        login() {

            let loginData = {
                phone: this.phone,
                password: this.psword
            }

            if (this.phone == "") {
                this.$message("请填写用户名")
                return false
            }
            if (this.psword == "") {
                this.$message("请填写密码")
                return false
            }

            if (this.radio == 2) {
                app.wpost({
                    url: "/AdminAjax/login.ashx?type=merchantlogin",
                    data: loginData,
                    succ: (data) => {
                        if(data.result == "success"){
                                                    
                            this.$message("登录成功")
                            sessionStorage.setItem("loginState", 1)
                            app.wpost({
                                url: "/AdminAjax/login.ashx?type=checkmerchantlogin",
                                succ: res => {
                                    console.log("商家登录check res:",res)
                                    sessionStorage.setItem("member", JSON.stringify(res.model))
                                    this.$router.push({
                                        path: "/shopManage/walfproduct"
                                    })
                                }
                            })
                        }   
                    },
                });
            } else {

                app.wpost({
                    url: "/Ajax/WordWelfare/login",
                    data: loginData,
                    succ: (data) => {
                        if(data.result == "success"){
                            
                        
                        this.$message("登录成功")
                        sessionStorage.setItem("loginState", 1)
                        sessionStorage.setItem("member", JSON.stringify(data.member))
                        this.$router.push({
                            path: "/home"
                        })
                        }
                    },
                });
            }

        }
    }
}
</script>

<style scoped>
.main {
    padding-top: 27vh;
    background-image: url("../assets/locart.jpg");
    background-position: center center;
    background-size: 100% auto;
    height: calc(100vh - 18px);
    box-sizing: border-box;
}

.radio-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav {
    position: absolute;
    top: 22px;
    right: 22px;
    color: #999;
}

.login-wrp {
    width: 50%;
    padding: 10px 20px;
    box-sizing: border-box;
    min-width: 300px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 0 0 12px #eee;
    max-height:320px;
}

.login-wrp .el-row {
    line-height: 40px;
    margin-bottom: 20px;
}

.login-btns .el-button {
    margin: 0 auto;
}
</style>
